import React, { Component } from "react";
import "./staticPage.scss";
import { Link } from "react-router-dom";
import AdobeService from "../../../_foundation/adobeService/adobeService";
class ContactUs extends Component {
  //Adobe Tag Manager
  ContactUsClickToCall = () => {
    // if (this.state.terms < 1) {
    (async () => {
      let res = await AdobeService.track({
        pageName: "contact us",
        onClickLinkName:"contact us:content action",
        onClick:"contact us:content action: click to call",
        variantName: "click-to-chat",
        siteSection: "contact us",
        hierarchy: "contact us",
        eventType: "click-to-call",
      });
      AdobeService.callSatellite(res);
    })();
    // }
  };

  componentDidMount(): void {
    (async () => {
      let res = await AdobeService.track({
        pageName: "contact us",
        variantName: "global-load",
        siteSection: "contact us",
        hierarchy: "contact us",
        eventType: "globalLoadContactUs",
      });
      AdobeService.callSatellite(res);
    })();
  }
  render() {
    return (
      <div className="lincoln-contact">
        <section className="page-hero" id="page-hero-0">
          <div className="page-hero__background object-cover page-hero__overlay page-hero__overlay--light-overlay">
            <img
              className="above-mobile-large"
              src="https://accessories.lincoln.com/gcs/B2C/lincoln/Static_Pages/ContactUs/Contact_HeroImage_man-with-smartphone_D.jpeg"
              data-preload="loaded"
              alt="Desktop Hero"></img>
            <img
              className="mobile-large-only"
              src="https://accessories.lincoln.com/gcs/B2C/lincoln/Static_Pages/ContactUs/Contact_HeroImage_man-with-smartphone_M.jpeg"
              data-preload="loaded"
              alt="Mobile Hero"></img>
          </div>
          <div className="page-hero__content container">
            <div className="page-hero__content-inner page-hero__content-inner--middle">
              <h1 className="page-hero__heading title--md">
                <p>
                  <span
                    style={{
                      fontSize: "48px",
                      letterSpacing: "3px",
                      lineHeight: "58px",
                    }}>
                    Chat with Us
                  </span>
                </p>
              </h1>
              <div className="page-hero__textarea rte">
                <p></p>
                <p>
                  <span style={{ fontWeight: 500, color:"#324047" }}>
                    Your happiness matters. If you have any questions about
                    Lincoln Accessories or Ford Performance Parts, please reach
                    out.
                  </span>
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </section>
        <div id="shopify-section-contact-boxes" className="shopify-section">
          <span></span>
          <section className="contact-boxes">
            <div className="contact-boxes__container container">
              <span></span>
              <div className="contact-box">
                <h2 className="contact-box__heading title--sm">Phone</h2>
                <div className="contact-box__inner">
                  <div className="contact-box__content">
                    <div className="contact-box__inner-content">
                      Monday - Friday
                      <br />
                      8:30AM - 5:30PM EST
                      <br />
                      <br />
                      <br />
                      Feel free to reach us outside of office hours and we will
                      respond as quickly as possible.
                    </div>
                  </div>
                  <a
                    className="contact-box__content-footer"
                    href="tel:844-589-0060"
                    data-adobe-click-to-call=""
                    onClick={() => this.ContactUsClickToCall()}>
                    <img
                      src="https://accessories.lincoln.com/gcs/B2C/lincoln/BackGround_Images/Contact_us/telephone.svg"
                      alt="Phone Icon"></img>
                    <span className="contact-box__footer-link-text ">
                      844-589-0060
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16">
                      <g>
                        <path
                          fill="#102b4e"
                          d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="contact-box">
                <h2 className="contact-box__heading title--sm">
                  Frequently Asked Questions
                </h2>
                <div className="contact-box__inner">
                  <div className="contact-box__content">
                    <div className="contact-box__inner-content">
                      Still have a question?
                      <br />
                      <br />
                      <br />
                      The answer is likely here in our FAQ area. If not, please
                      live chat with us or call.
                    </div>
                  </div>
                  <Link className="contact-box__content-footer" to="/Faq">
                    <span className="contact-box__footer-link-text contact-box__footer-link-text--underlined">
                      Frequently Asked Questions
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16">
                      <g>
                        <path
                          fill="#102b4e"
                          d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                      </g>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <div id="shopify-section-search-dealers" className="shopify-section">
          <section className="search-dealers">
            <div className="search-dealers__container container">
              <div className="search-dealers__inner">
                <h3 className="search-dealers__heading title--sm">
                  Search for Nearby Ford Dealers
                </h3>
                <div className="search-dealers__mobile-label">
                  Enter ZIP Code, City/State or Dealer
                </div>
                <form className="search-dealers__form" action="/pages/store-locator">
                  <label htmlFor="search-dealers-location" className="visually-hidden">
                    Location
                  </label>
                  <input className="search-dealers__input" id="search-dealers-location" type="text" name="location" placeholder="Enter ZIP Code, City/State or Dealer"></input>
                  <button className="search-dealers__submit" type="submit" aria-label="Search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><g><g><g><path fill="none" stroke="#112b4e" stroke-miterlimit="20" stroke-width=".75" d="M9.5 18a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17z"></path></g><g><path fill="#112b4e" d="M15.357 15.361c-.476.482-.476 1.446 0 1.928L22.977 25s.475 0 1.428-.964c.952-.964.476-.964.476-.964l-7.62-7.71c-.475-.482-1.428-.482-1.904 0z"></path></g></g></g></svg>
                    <span className="search-dealers__submit-mobile-text">
                      Search
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    );
  }
}
export default ContactUs;
