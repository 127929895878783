/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSite } from "../../../_foundation/hooks/useSite";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { useDispatch, useSelector} from "react-redux";
import { LOGIN_SUCCESS_ACTION } from "../../../redux/actions/user";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { HOME } from "../../../constants/routes";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { CURRENT_USER } from "../../../_foundation/constants/common";

const AfterLogin: React.FC = () => {
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const history = useHistory();
  const catalogId = mySite.catalogID;
  const storeId = mySite.storeID;
  const langId = mySite.defaultLanguageID;
  let [newLoginStatus, setNewLoginStatus] = useState<any>();
  const loginResponse = window.location.search;
  let paramString = loginResponse.split("?")[1];
  const isFMAEncodeEnabled = mySite.storeCfg.userData["FMA_ENCODED"] === 'true' ? true : false; 

  /* Temproary fix for  login from Affliate sites ex: ford.com we can remove once changes are done from backend/FMA*/
  if(!isFMAEncodeEnabled && loginResponse.split('?')[2] !== undefined && loginResponse.split('?')[2] !== '') {
    paramString = paramString + encodeURIComponent('?') + loginResponse.split('?')[2];
  }
  /* End */
  
  let queryString = new URLSearchParams(paramString);
  var FMALoginResponse = [{}];
  const isUserLoggedin = localStorageUtil.get(CURRENT_USER) && localStorageUtil.get(CURRENT_USER)?.userLoggedIn;
  useEffect(() => {
    if (isUserLoggedin && !loginResponse) {
      history.push(HOME);
      return;
    }
    if(!isUserLoggedin && loginResponse ) {
      for (let pair of queryString.entries()) {
        let obj = { "catalogId": catalogId, "langId": langId };
        obj[pair[0]] = pair[1];
        FMALoginResponse.push(obj);
      }
      var logininfo = Object.assign({}, ...FMALoginResponse);
      var loggedInInfo = JSON.stringify(logininfo);
      const userInfoJson : any = logininfo;
      localStorage.setItem("NewLoginStatus", "true");
      localStorage.setItem("storeId", userInfoJson.storeId);
      localStorage.setItem("FirstName", userInfoJson.firstName);
      localStorage.setItem("LastName", userInfoJson.lastName);
      localStorage.setItem("LastLogonValue", userInfoJson.logonTimestamp);
      localStorage.setItem("pid", userInfoJson.pid);
      localStorage.setItem("userLogonIdValue", decodeURIComponent(userInfoJson.userLogonId));
      localStorage.setItem("sessionId", userInfoJson.sessionId);
      localStorage.setItem('loginSuccessful',"true");

      if(userInfoJson?.logonTimestamp && userInfoJson?.pid) {
        axios.post(
          `/wcs/resources/store/${storeId}/fordloginidentity`,
          {
            "logonId": decodeURIComponent(userInfoJson.userLogonId),
            "logonPassword": "***",
            "pid": userInfoJson.pid,
            "logonTimestamp": userInfoJson.logonTimestamp,
            "firstName": userInfoJson.firstName,
            "lastName": userInfoJson.lastName
          },
          {
            headers: {
              'content-type': 'application/json',
            }
          }
        ).then(
          res => {
            const payload = {
              WCToken: res.data.WCToken,
              WCTrustedToken: res.data.WCTrustedToken,
              userId: userInfoJson.userLogonId,
              lastUpdated: userInfoJson.logonTimestamp,
              resourceName: 'fordloginidentity',
              personalizationID : res?.data?.personalizationID
              //personalizationID: userInfoJson.pid,
            }
            localStorageUtil.remove('shippingAddressId1');
            localStorageUtil.remove('shippingAddressId');
            dispatch({ type: LOGIN_SUCCESS_ACTION, payload });
            window.location.assign(decodeURIComponent(userInfoJson.currentURL));
          }
  
        )
      } else {
        window.location.assign(decodeURIComponent(window.location.origin)); 
      }
      
      // if (FMALoginResponse.length > 2 && loggedInInfo.includes("sessionId")) {
      //   axios.post(
      //     `/wcs/resources/store/${storeId}/Redirect/logOn`,
      //     loggedInInfo,
      //     {
      //       headers: {
      //         'content-type': 'application/json',
      //       }
      //     }
      //   ).then(res => {
      //     if (res?.data?.identitySignature != null || res?.data?.identitySignature != "") {
      //       const userInfoJson = JSON.parse(res.config.data);
      //       localStorage.setItem("NewLoginStatus", "true");
      //       localStorage.setItem("storeId", userInfoJson.storeId);
      //       localStorage.setItem("FirstName", userInfoJson.firstName);
      //       localStorage.setItem("LastName", userInfoJson.lastName);
      //       localStorage.setItem("LastLogonValue", userInfoJson.logonTimestamp);
      //       localStorage.setItem("pid", userInfoJson.pid);
      //       localStorage.setItem("userLogonIdValue", decodeURIComponent(userInfoJson.userLogonId));
      //       localStorage.setItem("sessionId", res.data.sessionId);
      //       localStorage.setItem('loginSuccessful',"true");

      //       axios.post(
      //         `/wcs/resources/store/${storeId}/fordloginidentity`,
      //         {
      //           "logonId": decodeURIComponent(userInfoJson.userLogonId),
      //           "logonPassword": "******",
      //           "pid": userInfoJson.pid,
      //           "logonTimestamp": userInfoJson.logonTimestamp
      //         },
      //         {
      //           headers: {
      //             'content-type': 'application/json',
      //           }
      //         }
      //       ).then(
      //         res => {
      //           const payload = {
      //             WCToken: res.data.WCToken,
      //             WCTrustedToken: res.data.WCTrustedToken,
      //             userId: userInfoJson.userLogonId,
      //             lastUpdated: userInfoJson.logonTimestamp,
      //             resourceName: 'fordloginidentity',
      //             personalizationID : res?.data?.personalizationID
      //             //personalizationID: userInfoJson.pid,
      //           }
      //           localStorageUtil.remove('shippingAddressId1');
      //           localStorageUtil.remove('shippingAddressId');
      //           dispatch({ type: LOGIN_SUCCESS_ACTION, payload });
      //           window.location.assign(decodeURIComponent(userInfoJson.currentURL));
      //         }
  
      //       )
      //     }
      //   })
      // }
    }
  }, [loginResponse]);

  return (
    <div
      style={{
        display: "flex",
        height: "80vh",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <CircularProgress />
    </div>
  );
};

export { AfterLogin };
