import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdobeService from "../../../_foundation/adobeService/adobeService";
class Return extends Component {
  //Adobe Tag Manager
  componentDidMount(): void {
    (async () => {
      let res = await AdobeService.track({
        pageName: "returns",
        variantName: "global-load",
        siteSection: "help center",
        hierarchy: "help center",
        eventType: "globalLoadReturns",
      });
      AdobeService.callSatellite(res);
    })();
  }
  render() {
    return (
      <div>
        <div id="shopify-section-standard__hero" className="shopify-section">
          <section className="page-hero" id="page-hero-">
            <div className="page-hero__background object-cover page-hero__overlay ">
              <img
                className="above-mobile-large"
                src="https://accessories.lincoln.com/gcs/B2C/lincoln/Static_Pages/Returns/StandardContentPages_ReturnsHero_RangerKayaks_D.jpeg"
                data-preload="loaded"
                alt="Desktop Hero"></img>
              <img
                className="mobile-large-only"
                src="https://accessories.lincoln.com/gcs/B2C/lincoln/Static_Pages/Returns/StandardContentPages_ReturnsHero_RangerKayaks_M_668f2a1c-0ae5-41a1-b0a6-c84002e223b0.jpeg"
                data-preload="loaded"
                alt="Mobile Hero"></img>
            </div>
            <div className="page-hero__content container">
              <div className="page-hero__content-inner page-hero__content-inner--middle">
                <h1 className="page-hero__heading title--md">Returns</h1>
              </div>
            </div>
          </section>
        </div>

        <div id="shopify-section-standard__content" className="shopify-section">
          <section className="content-section container--mb-2">
            <div className="page__content rte">
              <br></br>
              <br></br>
              <h1 style={{ color: "#4d4d4d" }}>RETURNS AND EXCHANGES</h1>
              <p
                style={{
                  fontWeight: 400,
                  letterSpacing: "2px",
                  fontSize: "17px",
                }}>
                Ford or the dealer (where applicable) will accept returns or
                exchanges of applicable Ford Parts, Ford Accessories, Lincoln
                Accessories and Ford Performance Parts within 30 days of your
                receipt. Once you have initiated a return, you must return the
                part(s) within 7 calendar days. Electronics, wheels,
                bedcaps,&nbsp;chemicals, lubricants and custom-painted parts
                cannot be returned at any time for any reason.
                <br />
                <br />
                All returns and exchanges must be returned to Ford (for orders
                shipped directly to you) or the dealer (for orders picked up
                from the dealer) solely at your expense, except in the case of
                Ford or dealer error. Returned items must be in the original
                box, in new, non-installed, resalable condition, with all
                instructions and hardware, and in the condition it was received.
                If all of these conditions are not met, it is at discretion of
                Ford or the dealer (where applicable) to accept the return.
                Shipping charges are non-refundable. Refunds will be credited to
                the same card or, in our discretion, other payment method.
                <br />
                <br />
                If you opt not to provide a VIN(s), you are solely responsible
                for making sure the accessories you have ordered are compatible
                with your vehicle.
                <br />
                <br />
                Attempting to return any parts or assemblies that have been
                tampered with in a manner that affects the re-salability and/or
                safety of the part(s) is prosecutable under the law, and these
                parts and assemblies are not eligible for return credit, refund
                and/or exchange.
                <br />
                <br />
                <span
                  style={{
                    fontWeight: 400,
                    letterSpacing: "2px",
                    fontSize: "17px",
                  }}>
                  You can&nbsp;
                </span>
                <Link
                  className="order__return"
                  to="/MakeReturn"
                  style={{ textDecoration: "underline", color: "#324047" }}>
                  make a return online
                </Link>
                <span
                  style={{
                    fontWeight: 400,
                    letterSpacing: "2px",
                    fontSize: "17px",
                  }}>
                  , by using the chat feature on the Site or by
                  calling&nbsp;844-589-0060.
                  <br />
                </span>
                <br />
                If you believe a product sold by Ford has a condition or defect
                that might make it unsafe, please report this safety concern
                immediately by using the chat feature on the Site or by
                calling&nbsp;
                <span>844-589-0060</span>.
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Return;
