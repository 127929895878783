import React, { Component } from "react";
import "./staticPage.scss";
import AdobeService from "../../../_foundation/adobeService/adobeService";
class TnC extends Component {
  //Adobe Tag Manager
  componentDidMount(): void {
    (async () => {
      let res = await AdobeService.track({
        pageName: "terms and condition",
        variantName: "global-load",
        siteSection: "help center",
        hierarchy: "help center",
        eventType: "globalLoad",
      });
      AdobeService.callSatellite(res);
    })();
  }
  render() {
    return (
      <div id="Tnc_hero" className="TnC-section">
        <section className="page-hero" id="page-hero-">
          <div className="page-hero__background object-cover page-hero__overlay page-hero__overlay--dark-overlay">
            <img
              className="above-mobile-large"
              src="https://accessories.lincoln.com/gcs/B2C/lincoln/Static_Pages/Terms%26Conditions/StandardContentPages_TermsHero_MustangShoreline_D.jpeg"
              data-preload="loaded"
              alt="Desktop Hero"></img>
            <img
              className="mobile-large-only"
              src="https://accessories.lincoln.com/gcs/B2C/lincoln/Static_Pages/Terms%26Conditions/StandardContentPages_TermsHero_MustangShoreline_M.jpeg"
              data-preload="loaded"
              alt="Mobile Hero"></img>
          </div>
          <div className="page-hero__content container">
            <div className="page-hero__content-inner page-hero__content-inner--middle">
              <h1 className="page-hero__heading title--md">
                Terms &amp; Conditions
              </h1>
            </div>
          </div>
        </section>
        <div id="shopify-section-standard__content" className="shopify-section">
          <section className="content-section container--mb-2" style={{fontSize: "16px",letterSpacing: "2px",marginTop:'60px', marginBottom:'60px',paddingRight:'0px'}}>
          <div
        className="page__content rte"
        style={{ fontSize: "16px",letterSpacing: "2px", }}
      >
        <p>LINCOLN ACCESSORIES WEBSITE</p>
        <p>TERMS &amp; CONDITIONS</p>
        <p>Date of Last Revision: February 8, 2023</p>
        <p>
        The Lincoln Accessories website (“Site”) is operated by Ford
Motor Company ("Ford") and the content on the Site is owned by
Ford or its affiliated companies. Accessories purchased through
this website are sold to you by Ford.
        </p>
        <p>
          THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION PROVISION AND
          CLASS ACTION WAIVER. THIS REQUIRES YOU TO USE ARBITRATION ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN CLASS ACTIONS. BY
          ACCEPTING THESE TERMS, YOU EXPRESSLY AGREE TO BE BOUND BY AND ABIDE BY
          THESE TERMS, INCLUDING THE MANDATORY INDIVIDUAL ARBITRATION PROVISION
          AND THE CLASS ACTION WAIVER PROVISION. IF YOU DO NOT AGREE TO THESE
          TERMS, YOU MUST IMMEDIATELY DISCONTINUE ALL USE OF THE SITE.
        </p>
        <p>
          <span
            >This website is available to U.S. residents (excluding U.S.
            territories, and the states of Arizona, Oklahoma, and Vermont) who
            are 18 years of age or older or the age of legal majority in their
            place of residence. You must also be physically located in the
            United States (excluding the U.S. territories, Arizona, Oklahoma,
            and Vermont) in order to purchase any products offered on the Site.
            Please read these Terms and Conditions (“Terms and Conditions”) and
            the Privacy Policy contained on the Site, as they, together with any
            additional terms to which you agree when using particular elements
            of the Site, constitute the entire agreement regarding the Site and
            set forth the legally binding terms governing your visit to this
            Site. These Terms and Conditions apply to all persons who visit this
            Site. By accessing or making a purchase from the Site you agree to
            be bound by these Terms and Conditions. If you do not agree to these
            Terms and Conditions, your sole recourse shall be to cease using
            this Site. Ford reserves the right to change the Terms and
            Conditions from time to time without notifying you. If you object to
            any such changes, your sole recourse shall be to cease using this
            Site. Continued use of the Site following the effective date of such
            changes shall indicate your acknowledgement of such changes and
            agreement to be bound by the Terms and Conditions of such changes.
            Ford may cease all or part of the Site at any time without
            notice.</span
          >
        </p>
        <p>
          Ford may make changes to any products or services offered on the Site,
          or to the applicable prices for any such products or services, without
          notice at any time. The products and services offered or referred to
          on this website are subject to availability.
        </p>
        <p>PRIVACY</p>
        <p>
          For an explanation of Ford's policies and practices regarding
          information that we may collect from you in connection with your use
          of this Site, you should review Ford's Privacy Policy. By using this
          Site, you acknowledge the Privacy Policy.
        </p>
        <p>UNITED STATES SITE</p>
        <p>
          <span
            >Ford administers this Site from Michigan, United States of America
            and this Site is only intended for viewing in the United States. All
            materials and information contained on this Site apply to the United
            States market only. Ford makes no representation that the Site is
            appropriate or available for use outside the United States. If you
            choose to access the
          </span>
        </p>
        <p>
          Site from outside the United States, you do so at your own initiative
          and are responsible for compliance with applicable laws. You agree to
          comply with all export and re-export laws, restrictions and
          regulations of your government in connection with your use of the
          Site.
        </p>
        <p>THIRD PARTY INFORMATION</p>
        <p>
          This Site may contain links to third-party websites that are not owned
          or controlled by Ford, including but not limited to websites for Ford
          dealers (“Third Party Websites”). Ford has no control over, and
          assumes no responsibility for, the content, privacy policies, or
          practices of any Third Party Websites. In addition, Ford will not and
          cannot censor or edit the content of any Third Party Website. By
          clicking on links, you expressly relieve Ford from any and all
          liability arising from your use of any Third Party Website, or from
          the content of any Third Party Website. When using a Third Party
          Website, you should be aware when you leave Ford's Site and read the
          terms and conditions and privacy policy of each Third Party Website
          that you visit.
        </p>
        <p>COMMUNICATIONS</p>
        <p>
          By providing your personal information, you agree to receive
          advertising, marketing materials and other communications from Ford
          and your dealer via print, email or other means. The information you
          provide will be handled according to the Ford Privacy Policy.
        </p>
        <p>TERMINATION OF USE</p>
        <p>
          Ford may, in its sole discretion, terminate your account or your use
          of the Site at any time. You are liable for any orders placed through
          your account or charges that you incur prior to termination. By
          entering an order, you accept personal liability for any charges that
          you may incur even if your account or use of the Site is subsequently
          terminated.
        </p>
        <p><span>DISPUTE RESOLUTION - ARBITRATION</span></p>
        <p>
          READ THE FOLLOWING ARBITRATION AGREEMENT CAREFULLY. IT LIMITS CERTAIN
          RIGHTS, INCLUDING YOUR RIGHT TO OBTAIN RELIEF OR DAMAGES THROUGH COURT
          ACTION OR AS A MEMBER OF A CLASS. IT CONTAINS PROCEDURES FOR MANDATORY
          BINDING ARBITRATION AND A CLASS ACTION WAIVER.
        </p>
        <p>
          All claims, disputes or controversies (whether in contract or tort,
          pursuant to statute or regulation, or otherwise, and whether
          pre-existing, present or future) arising out of or relating to: (a)
          these Terms and Conditions; (b) this website; (c) any advertisement or
          promotion relating to these Terms and Conditions or this Site; or (d)
          transactions effectuated through this website, or (e) the relationship
          which results from these Terms and Conditions (including relationships
          with third parties who are not party to these Terms and Conditions)
          (collectively "Claims"), will be referred to and determined by binding
          arbitration governed by the Federal Arbitration Act and administered
          by the American Arbitration Association under its rules for the
          resolution of consumer-related disputes, or under other mutually
          agreed procedures. Because this method of dispute resolution is
          personal, individual and provides the exclusive method for resolving
          such disputes, you further agree, to the extent permitted by
          applicable laws, to waive any right you may have to commence or
          participate in any class action or class-wide arbitration against Ford
          related to any Claim.
        </p>
        <p>
          This provision shall survive the termination of your right to use this
          website.
        </p>
        <p>LIMITED LICENSES:</p>
        <p>
          <span
            >Copyright 2021, Ford Motor Company. All rights reserved. This Site,
            including all materials, is protected by worldwide copyright laws
            and treaty provisions whether or not a copyright notice is present
            on the materials. All text, images, graphics, animation, videos,
            music, sounds and other materials on this Site are subject to the
            copyrights and other intellectual property rights of Ford, its
            affiliated companies and its licensors. You agree to comply with all
            copyright laws worldwide in your use of this Site and to prevent any
            unauthorized copying of the materials. Except as expressly provided
            herein, Ford does not grant any express or implied right to you
            under any patents, trademarks or copyrights.</span
          >
        </p>
        <p>
          Subject to your continued compliance with these terms and conditions,
          Ford grants you a non-exclusive, non-transferable, limited right to
          access, use, display and listen to this Site and the information,
          images, sounds and text ("materials") thereon. You agree not to
          interrupt or attempt to interrupt the operation of the Site in any
          way. You agree not to dispute Ford's claims of ownership or validity
          of its rights in the materials on this Site. Ford authorizes you to
          view and download the materials at this Site only for your personal,
          non-commercial use. Ford may suspend or terminate this authorization
          at any time for any reason. This authorization is not a transfer of
          title in the materials and copies of the materials and is subject to
          the following restrictions:
        </p>
        <ul>
          <li>
            you must retain, on all copies of the materials downloaded, all
            copyright and other proprietary notices contained in the materials;
          </li>
          <li>
            you may not modify the materials in any way or reproduce or publicly
            display, perform, distribute, or otherwise use them for any public
            or commercial purpose;
          </li>
          <li>
            you must not transfer the materials to any other person unless you
            give them notice of, and they agree to accept, the obligations
            arising under these terms and conditions of use.
          </li>
        </ul>
        <p>LINKING, CRAWLING AND ARCHIVING:</p>
        <p>
          If you would like to link to this Site, you must comply with the
          following guidelines:
        </p>
        <ul>
          <li>
            do not incorporate any content from this Site into your site (e.g.,
            by in-lining, framing or creating other browser or border
            environments around the website content). You may only link to, not
            replicate, the Site content;
          </li>
          <li>
            you may not use any Ford trademarks, logos, designs or service marks
            in your links;
          </li>
          <li>
            you may not create the appearance of a relationship or affiliation
            with Ford; and
          </li>
          <li>
            your site may not contain offensive, distasteful, illegal or
            inappropriate content.
          </li>
        </ul>
        <p>
          If you would like to use a web crawler or other search tool to gather
          information from the Site, you must comply with the provisions of the
          robots.txt file of the site and you may not circumvent CAPTCHA or
          other security measures.
        </p>
        <p><span>OTHER CONDITIONS/RESTRICTIONS</span></p>
        <p>
          CAUTION: ANY ATTEMPT BY ANY INDIVIDUAL TO DELIBERATELY DAMAGE ANY
          WEBSITE, TAMPER WITH THE SUBMISSION PROCESS, OR OTHERWISE UNDERMINE
          FORD’S LEGITIMATE BUSINESS OPERATIONS MAY BE A VIOLATION OF CRIMINAL
          AND CIVIL LAWS AND SHOULD SUCH AN ATTEMPT BE MADE, FORD RESERVES THE
          RIGHT TO COOPERATE IN THE PROSECUTION OF ANY SUCH INDIVIDUAL(S) AND TO
          PURSUE ALL REMEDIES TO THE FULLEST EXTENT PERMITTED BY LAW.
        </p>
        <p>TRADEMARK NOTICE</p>
        <p>
          The Ford name, and all trademarks and logos displayed on this Site are
          owned or used under license by Ford Motor Company. These trademarks
          include, but are not limited to, product brand names (e.g., Ford,
          Lincoln), vehicle model names (e.g., Mustang, Taurus, Explorer, F-150,
          Focus, Ranger), slogans (e.g.,Built Ford Tough, Ford Custom
          Accessories), and logos and emblems. The unauthorized use of any
          trademark displayed on this Site is strictly prohibited.
        </p>
        <p>CHANGES TO THE SITE</p>
        <p>
          Ford and its affiliates reserve the right to change, modify, suspend,
          discontinue or permanently cancel this Site's operation or portions
          thereof without any notice to you, including but not limited to
          product specifications or prices, at any time and from time to time
          without notice and without incurring any obligations. Your use of this
          Site after any changes are posted will be considered acceptance of
          such changes.
        </p>
        <p>INDEMNITY AND HOLD HARMLESS</p>
        <p>
          <span
            >By using this Site you agree that you will defend, indemnify and
            hold harmless Ford and its subsidiaries and affiliates, their
            respective distributors, dealers, dealer associations and
            advertising and promotions agencies, together with their respective
            employees, agents, directors, officers and shareholders, from and
            against all the liabilities, claims, damages and expenses (including
            reasonable attorneys' fees and costs) arising out of your use of
            this Site, your breach or alleged breach of these Terms and
            Conditions, and/or your breach or alleged violation of the patent,
            copyright, trademark, proprietary or other rights of third parties.
            Ford reserves the right to assume the exclusive defense and control
            of any matter otherwise subject to indemnification by you, and, in
            such case, you shall agree to cooperate with Ford's defense of such
            claim.</span
          >
        </p>
        <p>
          You agree to indemnify and hold harmless Ford and its affiliates and
          their respective directors, officers, employees, agents or other
          representatives (the “Ford Parties”) from and against all claims,
          liability and expenses, including all legal fees and costs arising
          from or relating to (a) your breach of these Terms and Conditions of
          Use; and (b) your use of this website including transmission or
          placement of information or material by you on this website. This
          provision shall survive the termination of your right to use this
          website.
        </p>
        <p>DESIGNATED AGENT FOR COPYRIGHT NOTIFICATION</p>
        <p>
          If you believe that any content on this Site infringes your copyright
          rights, please contact Ford's designated agent for receiving such
          notifications in writing as follows:
        </p>
               <p>
          DMCA Agent<br></br>Ford Motor Company<br></br>One American Road,
          4<sup>th</sup>&nbsp;Floor<br></br>Dearborn, Michigan 48126
          <br></br>Email address:{" "}
          <a
            style={{ textDecoration: "Underline", color: "#324047" }}
            href="mailto:DMCA@ford.com">
            DMCA@ford.com
          </a>
          <br></br>Phone number: (313) 323-2875
        </p>
        <p style={{ fontSize: "17px", letterSpacing: "1.5px" }}>
          In your communication, please include:
        </p>
        <ol style={{ listStyleType: 'decimal',paddingLeft: '50px', fontSize: "16px",letterSpacing: "2px", }}   >
          <li>Your physical or electronic signature.</li>
          <li>
            Identification of the copyrighted work you claim to have been
            infringed, or, if there are multiple copyrighted works, a
            representative list of such works.
          </li>
          <li>
            Identification of the material that you claim to be infringing, and
            information reasonably sufficient to permit us to locate the
            material.
          </li>
          <li>
            Information reasonably sufficient to permit us to contact you, such
            as an address, telephone number, and, if available, an email
            address.
          </li>
          <li>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by you or the
            law.
          </li>
          <li>
            A statement that the information in your notification is accurate.
          </li>
        </ol>
        <p>
          If the communication is submitted by someone on your behalf, the
          communication must also contain a statement that, under penalty of
          perjury, the submitter is authorized to act on your behalf.
        </p>
        <p>PRODUCT DESCRIPTIONS</p>
        <p>
          Ford attempts to ensure that the description and specification of our
          products are correct at the time of publication. Ford does not warrant
          that product descriptions or other content of this site are accurate,
          complete, reliable, current, or error-free. While the color
          reproduction of the products is a close representation, actual color
          may vary; we cannot accept any responsibility for any variation in
          color caused by the browser software or computer system used by you.
          If a product offered by Ford is not as described, your sole remedy is
          to return it in unused condition in accordance with our return policy.
        </p>
        <p>PRICING AND AVAILABILITY</p>
        <p>
          All prices for accessories, installation, and labor are shown in U.S.
          dollars; taxes, shipping and handling charges are additional. All
          items are subject to availability and we reserve the right to impose
          quantity limits on any order, to reject all or part of an order and to
          discontinue the availability of products or services without notice,
          even if you have already placed your order. Ford will inform you as
          soon as possible, before accepting your order, if the products you
          have ordered are not available. All prices are subject to change
          without notice and you agree that taxes may be adjusted from the
          amount shown on the billing screens. Several factors may cause this,
          such as variances between processor programs and changes in tax rates.
        </p>
        <p>
          For certain items that are offered as a set, the price may represent
          "open-stock" prices, which means the aggregate of the estimated price
          or suggested price for each of the items included in the set.
        </p>
        <p>
          Your credit card will be charged upon shipment of your order. If you
          use FordPass Rewards/Lincoln Access Rewards™ Points for a discount, the
          Points will be deducted from your Rewards Account immediately upon
          completion of your redemption transaction.
        </p>
        <p>TAXES</p>
        <p>
          The amount of tax charged for your order depends upon many factors,
          including:
        </p>
        <ul>
          <li>The identity of the seller</li>
          <li>The type of item purchased, and</li>
          <li>
            The destination of the shipment or location where you will pick it
            up.
          </li>
        </ul>
        <p>
          Dealers providing services on this site charge sales tax in the tax
          jurisdictions in which they have a physical presence. Taxes not
          collected by item may be your responsibility depending on the laws of
          the jurisdiction in which the sale takes place.
        </p>
        <p>HOW SALES TAX IS CALCULATED</p>
        <p>
          All prices on the Site are given in US Dollars ($) and do not include
          sales tax or delivery costs. The sales tax will vary depending on
          where the merchandise is shipped and will be calculated at checkout.
        </p>
        <p>
          If an item is subject to sales tax at the location where the sale
          takes place, tax is generally calculated on its total selling price.
          In accordance with state tax laws, the total selling price of an item
          generally includes item-level shipping charges, handling charges if
          applicable, discounts and an allocation of order-level shipping and
          handling charges and discounts.
        </p>
        <p>
          The tax rate applied to your order will generally be the combined
          state and local rate for the address where your order is shipped.
          Therefore, the sales tax rate applied to your order may be different
          for an order shipped to your home address than it is for an order for
          the very same items shipped to your work address or items picked up at
          the dealership.
        </p>
        <p>ESTIMATED TAX</p>
        <p>
          Many factors can change between the time you place an order and the
          time your order is shipped. Therefore, the amount appearing on your
          order as "Estimated Tax" may differ from the sales taxes ultimately
          charged.
        </p>
        <p>ERRORS</p>
        <p>
          We attempt to be as accurate as possible and to eliminate errors on
          the Site; however, we do not warrant that any product, service,
          description, photograph, pricing or other information is accurate,
          complete, reliable, current or error-free. In the event of an
          error—whether on the Site, in an order confirmation, in processing an
          order, in delivering a product or service or otherwise—we reserve the
          right to correct such error and to revise your order accordingly if
          necessary (including charging the correct price) or to cancel the
          order and refund any amount charged. Your sole remedy in the event of
          such error is to cancel your order and obtain a refund.<br /><br />OUT-OF-STOCK
          ITEMS; BACKORDERS
        </p>
        <p>
          If the product you want is not listed on the Product Information page,
          it is not then available for ordering and will be shown as out of
          stock. You may be able to request notification when it is back in
          stock. Some items may be backordered or unavailable even if the Site
          indicates that they are in-stock, and adding an item to your cart does
          not guarantee the availability of that item. We will not charge your
          payment card until the item ships to the designated delivery address.
          If you use FordPass Rewards/Lincoln Access Rewards Points for a
          discount, the Points will be deducted from your Rewards Account
          immediately upon completion of your redemption transaction. If the
          backordered item is no longer available, we will contact you to
          determine how to proceed or cancel the item from your order and notify
          you, usually via e-mail. If you have items on backorder that you would
          like to cancel, please contact us at 844-589-0060.
        </p>
        <p>
          AGREEMENT TO CONDUCT TRANSACTIONS ELECTRONICALLY; RECORDING; COPIES
        </p>
        <p>
          You agree that all of your transactions with or through the Site may,
          at our option, be conducted electronically from start to finish, and
          that any oral conversations may be recorded. If we decide to proceed
          non-electronically, those transactions will still be governed by the
          remainder of these Terms unless you enter into different terms
          provided by us. You are responsible to print or make an electronic
          copy of these Terms and any other contract or disclosure that we are
          required to provide to you.
        </p>
        <p>SHIPPING &amp; HANDLING; NO EXPORT BY YOU</p>
        <p>
          When we ship to you or per your directions, you agree to pay the
          shipping and any handling charges shown on the Site when your order is
          placed. We reserve the right to increase, decrease, add or eliminate
          charges from time to time and without prior notice, so you agree to
          check all charges before placing an order or signing up for a service.
          When you ask us to ship to a Ford store for pick-up, shipping is free
          (however, some exclusions may apply, and we reserve the right to add
          shipping and handling charges once we post them on the Site).
          Generally, shipping is by standard ground delivery. Any shipping or
          handling charges may or may not reflect actual costs. All orders are
          shipment contracts, not destination contracts, including orders
          shipped to a Ford dealership for pick-up. Any shipping times shown on
          the Sites are estimates only – actual delivery dates may vary. You
          agree that you will not obtain or direct shipment of product for
          export.
        </p>
        <p>
          Ford cannot ship to PO, FPO or APO boxes, Arizona, Oklahoma, Vermont,
          or international addresses.
        </p>
        <p>
          Ford reserves the right to refuse to replace orders for customers who
          have made excessive loss and replacement requests determined at the
          sole discretion of Ford.
        </p>
        <p>Shipping and handling charges are subject to change.</p>
        <p>ORDER ACCEPTANCE/CONFIRMATION/CANCELLATION</p>
        <p>
          Your receipt of an electronic or other form of order confirmation does
          not constitute Ford’s acceptance of your order, nor does it constitute
          confirmation of Ford’s offer to sell. Ford reserves the right at any
          time after receipt of your order to decline or cancel your order for
          any reason in their sole discretion.
        </p>
        <p>
          You may cancel an order by contacting Ford at 844-589-0060 prior to
          the order being processed. Once an order is processed, it is your sole
          responsibility to contact Ford directly if you wish to cancel the
          order to determine whether or not an order can be cancelled.
        </p>
        <p>
          Ford may cancel your Dealer Pick-Up order if you do not pick up the
          order from your Dealer within 14 days of receiving notification that
          the order is ready for pick-up. You will be refunded for the order via
          the same method of payment you used to place the order. If you used
          Points for a discount, your refund will be a mixture of a credit to
          your credit card and a return of the Points redeemed to your Rewards
          Account. The proportion of the return that is credited to your credit
          card and returned to your Rewards Account will match the proportion of
          the credit card purchase/Point redemption in the original purchase.
        </p>
        <p>RETURNS AND EXCHANGES</p>
        <p>
          Ford or the dealer (where applicable) will accept returns or exchanges
          of applicable Ford Parts, Ford Accessories, Lincoln Accessories and
          Ford Performance Parts within 30 days of your receipt. Once you have
          initiated a return, you must return the part(s) within 7 calendar
          days. Electronics, wheels, bedcaps, chemicals and lubricants, and
          custom-painted parts cannot be returned at any time for any reason.
        </p>
        <p>
          All returns and exchanges must be returned to Ford (for orders shipped
          directly to you) or the dealer (for orders picked up from the dealer)
          solely at your expense, except in the case of Ford or dealer error.
          Returned items must be in the original box, in new, non-installed,
          resalable condition, with all instructions and hardware, and in the
          condition it was received. If all of these conditions are not met, it
          is at discretion of Ford or the dealer (where applicable) to accept
          the return. Shipping charges are non-refundable
        </p>
        <p>
          If you used Points for a discount on your order, your refund will be a
          mixture of a credit to your credit card and a return of the Points
          redeemed to your Rewards Account. The proportion of the return that is
          credited to your credit card and returned to your Rewards Account will
          match the proportion of the credit card purchase/Point redemption in
          the original purchase. Refunds will be credited to the same card or,
          in our discretion, other payment method. Points will be returned to
          the same Rewards Account.
        </p>
        <p>
          If you opt not to provide a VIN(s), you are solely responsible for
          making sure the accessories you have ordered are compatible with your
          vehicle.
        </p>
        <p>
          Attempting to return any parts or assemblies that have been tampered
          with in a manner that affects the re-salability and/or safety of the
          part(s) is prosecutable under the law, and these parts and assemblies
          are not eligible for return credit, refund and/or exchange.
        </p>
        <p>
          If you believe a product sold by Ford has a condition or defect that
          might make it unsafe, please report this safety concern immediately by
          using the chat feature on the Site or by calling (844) 589-0060.
        </p>
        <p>LIMITED WARRANTY</p>
        <p>
          Ford warrants that new Ford branded products will be free from defects
          in materials and workmanship at the time of sale. This warranty does
          not cover damage or malfunctions caused by normal wear and tear,
          accident, abuse, neglect, improper storage or handling, misuse,
          vandalism, acts of God, or other causes external to the product,
          including installation by you or Dealer.<br />This warranty gives you
          specific legal rights, and you may have other rights, which vary from
          State to State.
        </p>
        <ul>
          <li>
            Ford Accessories are defined as products offered as Ford Accessories
            on the Site, although not all Custom Accessories purchased through
            this website are Genuine Ford Accessories which are warranted by
            Ford. This website also contains Ford Licensed Accessories which are
            warranted by their respective manufacturers and are not warranted by
            Ford.
          </li>
          <li>
            Ford Original Accessories (FOA) will be warranted for whichever
            provides you the greatest benefit: 24 months/unlimited mileage or
            the remainder of your Bumper-to-Bumper 3-year/36,000-mile New
            Vehicle Limited Warranty. Contact your local Ford dealer for details
            and a copy of the FOA limited warranty. Genuine Ford Accessories
            will be warranted for whichever provides you the greatest benefit:
            12 months or 12,000 miles (whichever occurs first) or the remainder
            of your Bumper-to-Bumper New Vehicle Limited Warranty. Contact your
            local Ford or Lincoln dealer for details and a copy of the limited
            warranty.
          </li>
          <li>
            Ford Licensed Accessories (FLA) are warranted by the accessories
            manufacturer's warranty. FLA are warranted by the accessory
            manufacturer for whichever provides you with the greatest benefit:
            24 months/unlimited mileage or the remainder of your
            Bumper-to-Bumper 3-year/36,000-mile New Vehicle Limited Warranty if
            bought at time of vehicle sale. Contact your local Ford dealer for
            details and a copy of the FLA limited warranties.
          </li>
          <li>
            Most Ford Performance Parts (FPP) are sold with no warranty, unless
            otherwise expressly designated. Check the FPP website:
            www.performanceparts.ford.com or contact the Ford Performance Parts
            Techline at (800) FORD788 for the latest limited warranty
            information on specific products.
          </li>
        </ul>
        <p>
          EXCLUSIVE REMEDY FOR ANY BREACH OF WARRANTY; LIMITATION OF LIABILITY
        </p>
        <p>
          Your sole and exclusive remedy, and Ford's sole and exclusive
          liability, for any breach of warranty by Ford shall be your right to
          return the product, or receive a refund as provided in Ford's Return
          Policy.
        </p>
        <p>DISCLAIMER</p>
        <p>
          NOTE THAT THESE TERMS AND CONDITIONS APPLY ONLY TO THIS SITE, OTHER
          WEBSITES PROVIDED BY FORD OR ITS AFFILIATES MAY HAVE DIFFERENT TERMS
          AND CONDITIONS THAT APPLY TO THE USE OF THOSE SITES. YOU ARE
          RESPONSIBLE FOR READING AND UNDERSTANDING THE TERMS AND CONDITIONS OF
          THOSE SITES. This Site and all the information it contains is provided
          for information purposes only on an "as is" basis and could include
          technical, typographical or other errors. In certain areas, the
          information provided may represent an opinion or judgment. Ford,
          information providers and their agents make no warranties,
          representations, or guarantees of any kind, express or implied,
          including but not limited to, accuracy, currency, or completeness, the
          operation of the Site, the information, materials, content,
          availability, and products. To the extent permitted by applicable law,
          Ford disclaims all warranties, express or implied, including but not
          limited to, implied warranties of merchantability and fitness for a
          particular purpose. Ford is not responsible for the content of any
          Site linked to this Site and is not directly or indirectly implying
          any approval, endorsement or affiliation with any linked Site.
        </p>
        <p>
          YOU AGREE THAT YOUR USE OF THIS SITE SHALL BE AT YOUR SOLE RISK. TO
          THE FULLEST EXTENT PERMITTED BY LAW, FORD'S OFFICERS, DIRECTORS,
          EMPLOYEES, PARENTS, AFFILIATES, SHAREHOLDERS, REPRESENTATIVES AND
          AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
          THIS SITE AND YOUR USE THEREOF. FORD, ITS INFORMATION PROVIDERS AND
          THEIR AGENTS, MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENT
          OF ANY SITES LINKED TO THIS SITE AND ASSUMES NO LIABILITY OR
          RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
          WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE OR THE
          PURCHASE OF ANY PRODUCTS VIA THE SITE, (III) ANY UNAUTHORIZED ACCESS
          TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
          INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEB SITE,
          (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
          TRANSMITTED TO OR THROUGH OUR WEB SITE BY ANY THIRD-PARTY, AND/OR (V)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF
          ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
          EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEB SITE.
          FORD DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
          FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD-PARTY
          THROUGH THE WEB SITE OR ANY HYPERLINKED WEB SITE OR FEATURED IN ANY
          BANNER OR OTHER ADVERTISING, AND FORD WILL NOT BE A PARTY TO OR IN ANY
          WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
          THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF
          A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
          SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </p>
        <p>LIMITATION OF LIABILITY</p>
        <p>
          IN NO EVENT SHALL THE FORD PARTIES BE LIABLE FOR SPECIAL, INDIRECT,
          INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS OR LOSS
          OF BUSINESS, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES, NOR SHALL THE AGGREGATE LIABILITY OF THE FORD PARTIES
          (JOINTLY), WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE,
          WHETHER ACTIVE, PASSIVE OR IMPUTED), OR OTHER THEORY, ARISING OUT OF
          OR RELATING TO THESE TERMS OR THE PURCHASE OR USE OF ANY PRODUCTS OR
          SERVICES PURCHASED THROUGH THE SITES EXCEED THE PURCHASE PRICE OF THE
          PRODUCT OR SERVICE.
        </p>
        <p>
          THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT LIMIT OR EXCLUDE
          THE FORD PARTIES' LIABILITY FOR PERSONAL INJURY OR PROPERTY DAMAGE
          CAUSED BY PRODUCTS YOU PURCHASE THROUGH THE SITES, OR FOR THE FORD
          PARTIES' GROSS NEGLIGENCE, FRAUD, INTENTIONAL, WILLFUL, MALICIOUS OR
          RECKLESS MISCONDUCT.
        </p>
        <p>
          Some States do not allow the exclusion or limitation of incidental or
          consequential damages, so the above limitations or exclusions may not
          apply to you.
        </p>
        <p>COMPLAINTS AND LEGAL NOTICES</p>
        <p>
          All complaints and legal notices should be mailed to Ford, Attn: Legal
          Department, 1 American Road, Dearborn, MI 48126. If a law requires us
          to accept legal notices via email, call (844) 589-0060 to ask for the
          address intended for receipt of such notices. If you are a California
          resident, the Complaint Assistance Unit of the Division of Consumer
          Services of the Department of Consumer Affairs may be contacted at
          1625 North Market Blvd., Suite N 112, Sacramento, CA 95834 or (800)
          952-5210.
        </p>
      </div>
          </section>
        </div>
      </div>
    );
  }
}

export default TnC;
