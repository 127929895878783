import * as React from "react";
import { styled } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  }
}));

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModel(false);
  };

  React.useEffect(() => {
    setOpen(true);
  }, []);
  return (<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="verizona-popup"
        open={open}>
        <div className="state-popup arizonapop" data-state-popup="">
          <div className="state-popup__inner flex f-dir-column ">
            <div className="state-popup__close" data-state-popup-close="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                onClick={handleClose}>
                <g>
                  <g>
                    <path
                      fill="#fff"
                      d="M14.862.195a.667.667 0 0 1 1.013.86l-.07.083L8.943 8l6.862 6.862a.667.667 0 1 1-.943.943L8 8.943l-6.862 6.862a.667.667 0 0 1-.86.07l-.083-.07a.667.667 0 0 1-.07-.86l.07-.083L7.057 8 .195 1.138a.667.667 0 1 1 .943-.943L8 7.057z"></path>
                  </g>
                </g>
              </svg>
            </div>
            <img
              src="https://accessories.lincoln.com/gcs/B2C/ford/US_Accessories/HomePage/Arizona/PopUp_HeroImage_BroncoRockyTerrain_D_1b289964-72b0-4fb9-86aa-9ffb43a85a29.jpeg"
              data-preload="loaded"
              alt="In Arizona, Oklahoma or Vermont?"
              className="above-tablet"
            />

            <img
              className="tablet-only"
              src="https://accessories.lincoln.com/gcs/B2C/ford/US_Accessories/HomePage/Arizona/PopUp_HeroImage_BroncoRockyTerrain_D_1b289964-72b0-4fb9-86aa-9ffb43a85a29.jpeg"
              data-preload="loaded"
              alt="In Arizona, Oklahoma or Vermont?"
            />

            <div className="state-popup__text-content text-align-center">
              <h3 className="title--xs m-b-s">
                In Arizona, Oklahoma or Vermont?
              </h3>
              <div className="rte sm">
                <p>
                  We do not currently service your area from this store. Please
                  select the brand you're looking to browse and we will redirect
                  you.
                </p>
              </div>
              <div className="state-popup__buttons">
                <a
                  href="https://parts.ford.com/shop/en/us/accessories"
                  target="_blank"
                  className="btn linbutton ">
                  Ford Accessories
                </a>

                <a
                  href="https://parts.ford.com/shop/en/us/accessories"
                  target="_blank"
                  className="btn btn--border accbutton">
                  Lincoln Accessories
                </a>
              </div>
              <div className="state-popup__footnote rte">
                <p>
                  Not ordering to one of these states? Click{" "}
                  <span
                    data-state-popup-close=""
                    onClick={handleClose}
                    className="state-popup__text-close underline color-primary">
                    here
                  </span>{" "}
                  to continue shopping.
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
      </BootstrapDialog>
  );
}
