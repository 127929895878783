/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// //Foundation libraries
// import { useSite } from "../../_foundation/hooks/useSite";
// //Custom libraries
// import { CONSTANTS } from "../../constants/order-item-table";
// import FormattedPriceDisplay from "../../components/widgets/formatted-price-display";
// import { INVENTORY_STATUS } from "../../constants/order";
// import { PAGINATION_CONFIGS } from "../../configs/order";
// //Redux
// import { currentContractIdSelector } from "../../redux/selectors/contract";
// import * as orderActions from "../../redux/actions/order";
// import {
//   forUserIdSelector,
//   loginStatusSelector,
//   userIdSelector,
// } from "../../redux/selectors/user";
//UI
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import {
    StyledAvatar,
    StyledGrid,
    StyledTypography,
    StyledNumberInput,
    StyledIconButton,
    TableColumnDef,
} from "@hcl-commerce-store-sdk/react-component";
import { useSite } from "../../../_foundation/hooks/useSite";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { forUserIdSelector, loginStatusSelector, userIdSelector } from "../../../redux/selectors/user";
import { INVENTORY_STATUS } from "../../../constants/order";
import FormattedPriceDisplay from "../../widgets/formatted-price-display";
import { cartSelector } from "../../../redux/selectors/order";
import { useWinDimsInEM } from "../../../_foundation/hooks/use-win-dims-in-em";
import AsyncCall from "../../../_foundation/gtm/async.service";
import { CONSTANTS } from "../../../constants/order-item-table";
import * as orderActions from "../../../redux/actions/order";
import { XS_MOBILE_W } from "../../../constants/common";
import HTMLReactParser from "html-react-parser";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import AdobeService from "../../../_foundation/adobeService/adobeService";
//GA360
// import AsyncCall from "../../_foundation/gtm/async.service";
// import { cartSelector } from "../../redux/selectors/order";
// import { useWinDimsInEM } from "./use-win-dims-in-em";
// import { XS_MOBILE_W } from "../../constants/common";

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
export const MiniCartOrderList = (props: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mySite } = useSite();
    const CancelToken = Axios.CancelToken;
    let cancels: Canceler[] = [];
    const contractId = useSelector(currentContractIdSelector);
    const loginStatus = useSelector(loginStatusSelector);
    const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;


    const dataProps = props.data;
    /**
     * Initialize table data by making a copy
     * Material-table alters the input data, so data cannot be of immutable type
     * @returns Copy of the data prop
     */
    const data = useMemo(() => {
      const newData = (dataProps ?? []).map((oi) => ({ ...oi }));
      localStorageUtil.set('cartItems', newData)
      return newData;
    }, [dataProps]);

    const readOnly = props.readOnly !== undefined ? props.readOnly : true;
    const miniCartView =
      props.miniCartView !== undefined ? props.miniCartView : false;

    const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
    //update this flag as need later
    const pagination = !readOnly && !miniCartView;
    const handleMiniCartClose =
      props.handleMiniCartClose !== undefined ? props.handleMiniCartClose : null;
    /**
     * Initialize quantity data per order item
     * @returns quantities object for each order item
     */
    const initQuantityData = () => {
      let newData: any = {};
      if (dataProps) {
        dataProps.map((oi) => {
          if (oi.quantity) {
            try {
              const parsedQty = parseInt(oi.quantity);
              if (parsedQty > 0) {
                newData[oi.orderItemId] = parsedQty;
              }
            } catch (e) {
              console.log("Could not parse quantity");
            }
          }
          return null;
        });
      }
      return newData;
    };
    const [quantityList, setQuantityList] = useState<any>(initQuantityData());
    const defaultOptions = {
      toolbar: false,
      header: !miniCartView,
      // paging: pagination,
      // pageSize: PAGINATION_CONFIGS.pageLimit,
      // pageSizeOptions: PAGINATION_CONFIGS.pageSizeOptions,
      actionsColumnIndex: -1,
      fixedColumns: {
        left: 0,
        right: 0,
      },
    };
    let options = props.options !== undefined ? props.options : defaultOptions;
    options.paging = false;
    const payloadBase: any = {
      currency: defaultCurrencyID,
      contractId: contractId,
      widget: "Order Item Table",
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
  };
  /*
  const QuantityCell = ({ rowData, ...props }) => {
    const forUserId = useSelector(forUserIdSelector);
    const uId = useSelector(userIdSelector);
    const userId = forUserId ?? uId;
    const cart = useSelector(cartSelector);
    const itemMemberId = rowData.xitem_memberId;
    const { w } = useWinDimsInEM();
    const mobile = !miniCartView && w > XS_MOBILE_W ? true : undefined;
    const disabled =
      rowData.freeGift === "true" ||
      (cart?.buyerId !== userId && userId !== itemMemberId);
    /**** SDS restriction logic for cart page  */
    /*let isSDSRestriction: any = false;
    if (rowData && rowData.attributes) {
      isSDSRestriction =
        rowData &&
        rowData.attributes.find((item) => {
          if (item.identifier === "SupplierName") {
            if (item.values[0].value === "SDS-TAG") {
              return item;
              // setIsRestriction(true);
            } else if (item.values[0].value === "SDS-Kemcrest") {
              return item;
              // setIsRestriction(true);
            }
          }
        })
          ? true
          : false;
    }

    /*****End SDS restriction logic */

    /**
     * Dispatch quantity update action for order item
     * @param item The selected order item
     */
    /*const onQuantityUpdate = (quantityString: string, item: any) => {
      if (item) {
        try {
          const quantity = parseInt(quantityString);
          if (quantity > 0) {
            let payload = {
              ...payloadBase,
              quantity: quantity.toString(),
              orderItemId: item.orderItemId,
              fetchCatentries: true,
            };
            setTimeout(() => {
              dispatch(orderActions.UPDATE_ITEM_ACTION(payload));
            }, 200);
            // update this (even tho it's temporary) just so that once the dispatch
            //   action completes, we don't "temporarily" revert back to the old value
            quantityList[rowData.orderItemId] = quantity;
          }
        } catch (e) {
          console.log("Could not parse quantity");
        }
      }
    };
    return (
      <div>
        <StyledNumberInput
          mobile={false}
          className="product-quantity__input "
          value={quantityList[rowData.orderItemId]}
          min={1}
          step={1}
          precision={0}
          disabled={disabled || isSDSRestriction}
          onChange={(event) => onQuantityUpdate(event, rowData)}
          stopLoadingOnUpdateValue={rowData}
          //debounceTiming={250}
          strict
        />
      </div>
    );
  };
  */
  const DeleteActionCell = ({ rowData, ...props }) => {
    const cart = useSelector(cartSelector);
    const forUserId = useSelector(forUserIdSelector);
    const uId = useSelector(userIdSelector);
    const userId = forUserId ?? uId;
    const itemMemberId = rowData.xitem_memberId;
    const [clicked, setClicked] = useState<boolean>(false);
    const disabled =
      clicked ||
      rowData.freeGift === "true" ||
      (cart?.buyerId !== userId && userId !== itemMemberId);

    /**
     * Dispatch action to remove selected order item
     * @param item The selected order item
     */

    const asynRemoveItem = async (item: any) => {
      const orderItemId = item.orderItemId;
      let payload = {
        ...payloadBase,
        orderItemId: orderItemId,
        fetchCatentries: true,
      };
      setClicked(true);

      if (dataProps.length === 1) {
          //Remove last Item Action - cart
          dispatch(orderActions.REMOVE_ALL_ITEM_ACTION(payload));
      } else {
          //Remove the Item Action - cart
           dispatch(orderActions.REMOVE_ITEM_ACTION(payload));
      }

      //GA360
      /*if (mySite.enableGA) {
        AsyncCall.sendRemoveFromCartEvent(item, {
          enableUA: mySite.enableUA,
          enableGA4: mySite.enableGA4,
        });
      }*/

      (async () => {
        let res = await AdobeService.track({
          pageName: "cart:cart view",
          onclickLinkName: "cart:remove",
          onclick: "cart:remove",
          siteSection: "cart",
          hierarchy: "cart:cart view",
          SKU: item.partNumber,
          orderItemId: item.orderItemId,
          quantity: item.quantity,
          name: item.name,
          unitPrice: item.unitPrice,
          availability: item.orderItemInventoryStatus,
          totalCost: item.orderItemPrice,
          variantName: "remove-cart",
          eventType: "removeCart",
        });
        AdobeService.callSatellite(res);
      })();

    };

    const removeItem = async (item: any) => {
      //Remove Item from Cart
      await asynRemoveItem(item);
    }

    return (
      <StyledIconButton
        disabled={disabled}
        color="primary"
        className="cart-item__image-remove"
        style={{ border: 0, fontSize: 12 }}
        onClick={() => removeItem(rowData)}>
        REMOVE
      </StyledIconButton>
    );
  };
  useEffect(() => {
    setQuantityList(initQuantityData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterAssociatedInstall = (xitem) => {
    xitem = xitem.split("-");
    let findPartnumber = (num) => {
        return num.xitem_field2 === xitem[0]
    }
    let filterValue = dataProps.filter(findPartnumber)
    return filterValue[0].partNumber;
}
const onQuantityUpdate = (quantityString: string, item: any) => {
    if (item) {
        try {
            const quantity = parseInt(quantityString);
            if (quantity > 0) {
                let payload = {
                    ...payloadBase,
                    quantity: quantity.toString(),
                    orderItemId: item.orderItemId,
                    fetchCatentries: true,
                };
                dispatch(orderActions.UPDATE_ITEM_ACTION(payload));

                // update this (even tho it's temporary) just so that once the dispatch
                //   action completes, we don't "temporarily" revert back to the old value
                quantityList[item.orderItemId] = quantity;
            }
        } catch (e) {
            console.log("Could not parse quantity");
        }
    }
};
const forUserId = useSelector(forUserIdSelector);
const uId = useSelector(userIdSelector);
const cart = useSelector(cartSelector);
const disabledInputQty = (rowData) => {
    const userId = forUserId ?? uId;
    const itemMemberId = rowData.xitem_memberId;
    const disabled =
        rowData.freeGift === "true" ||
        (cart?.buyerId !== userId && userId !== itemMemberId);
        let isXrestrictedquantity: any = false;
        if (rowData && rowData.attributes) {
          isXrestrictedquantity = rowData && rowData.attributes.find(item => {
            if (item.identifier === 'xrestrictedquantity') {
              return item;
            }
            }) ? true : false;
        }
        return disabled || isXrestrictedquantity;

    //     /*****End Xrestriction logic */
}
return (
    <StyledGrid>
      <table className="side-cart-items">
        <tbody>
          {dataProps?.map((rowData) => (
            <tr className="cart-item cart-item--side-cart">
              <td className="cart-item__information">
                <div className="cart-item__image">
                  <a className="cart-item__image-link">
                    <img alt={rowData.name} src={rowData.thumbnail} />
                  </a>
                  {/* <span className="cart-item__image-remove">
                                    Remove
                                </span> */}
                  <DeleteActionCell rowData={rowData} />
                </div>
                <div className="cart-item__details">
                  <span className="cart-item__part-number">
                    {rowData.partNumber !== "Installation"
                      ? "Part No: " + rowData.partNumber
                      : "Associated Installation Product: " +
                        filterAssociatedInstall(rowData.xitem_field2) +
                        "-Installed"}
                  </span>
                  <h2 className="cart-item__title">
                    {rowData.name && HTMLReactParser(rowData.name)}
                  </h2>
                  {rowData.partNumber !== "Installation" && (
                    <>
                      {rowData["xitem_field2"] === undefined ? (
                        <span className="cart-item__variant-value">
                          No Installation
                        </span>
                      ) : (
                        <span className="cart-item__variant-value">
                          With Installation
                        </span>
                      )}
                    </>
                  )}
                  <div className="cart-item__mobile-quantity-and-price">
                    <div className="product-quantity ">
                    {rowData && <>
                        <StyledNumberInput
                            mobile={false}
                            className="product-quantity__input "
                            value={quantityList[rowData.orderItemId]}
                            min={1}
                            step={1}
                            precision={0}
                            disabled={disabledInputQty(rowData)}
                            onChange={(event) => onQuantityUpdate(event, rowData)}
                            stopLoadingOnUpdateValue={rowData}
                            debounceTiming={250}
                            strict
                        /></>}
                    </div>
                    <div className="cart-item__price cart-item__price--total">
                      <FormattedPriceDisplay
                        min={parseFloat(rowData.orderItemPrice)}
                        currency={rowData.currency}
                      />
                      <label className="FRPinstallation">
                        or redeem up to{" "}
                        <b>
                          {(rowData.orderItemPrice / 0.005).toLocaleString(
                            "en-US"
                          )}{" "}
                          Points
                        </b>
                      </label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledGrid>
  );
};
